import { createAction } from '@reduxjs/toolkit'
import { IMemberModel, IProfileModel, IUserModel, TEmailNotificationItem } from '@core/api/types'
import { IExternalEmojiType } from '@containers/Settings/Modals/PlayaNFTs/types'
import { PROFILE_TYPES } from './types'

export const fetchProfile = createAction(PROFILE_TYPES.FETCH_PROFILE_REQUEST)

export const fetchProfileSuccess = createAction<IUserModel>(PROFILE_TYPES.FETCH_PROFILE_SUCCESS)

export const fetchProfileFailure = createAction(PROFILE_TYPES.FETCH_PROFILE_FAILURE)

export const fetchProfileFee = createAction(PROFILE_TYPES.FETCH_PROFILE_FEE_REQUEST)

export const fetchProfileFeeSuccess = createAction<IMemberModel>(
  PROFILE_TYPES.FETCH_PROFILE_FEE_SUCCESS,
)

export const fetchProfileFeeFailure = createAction(PROFILE_TYPES.FETCH_PROFILE_FEE_FAILURE)

export const modifyProfile = createAction<Partial<IUserModel>>(PROFILE_TYPES.UPDATE_USER)

export const modifyProfileSuccess = createAction(PROFILE_TYPES.UPDATE_USER_SUCCESS)

export const modifyProfileFailure = createAction(PROFILE_TYPES.UPDATE_USER_FAILURE)

export const fetchUpdateProfile = createAction<Partial<IProfileModel>>(
  PROFILE_TYPES.UPDATE_PROFILE_REQUEST,
)

export const updateProfileSuccess = createAction(PROFILE_TYPES.UPDATE_PROFILE_SUCCESS)

export const updateProfileFailure = createAction(PROFILE_TYPES.UPDATE_PROFILE_FAILURE)

export const fetchSaveProfile = createAction<Partial<IProfileModel>>(
  PROFILE_TYPES.SAVE_PROFILE_REQUEST,
)

export const saveProfileSuccess = createAction(PROFILE_TYPES.SAVE_PROFILE_SUCCESS)

export const saveProfileFailure = createAction(PROFILE_TYPES.SAVE_PROFILE_FAILURE)

export const postCountry = createAction<{ country: string }>(PROFILE_TYPES.POST_COUNTRY)

export const successUser = createAction<IUserModel>(PROFILE_TYPES.SUCCESS_USER)

export const getUsaDisclaimer = createAction(PROFILE_TYPES.GET_USA_DISCLAIMER)

export const uploadProfilePicture = createAction<FormData>(
  PROFILE_TYPES.FETCH_UPLOAD_PROFILE_PICTURE_REQUEST,
)

export const deleteProfilePicture = createAction(PROFILE_TYPES.FETCH_DELETE_PROFILE_PICTURE_REQUEST)

export const logout = createAction(PROFILE_TYPES.LOGOUT_REQUEST)

export const setAuthPopupOpen = createAction<{ isOpen: boolean; titleKey?: string }>(
  PROFILE_TYPES.SET_AUTH_POPUP,
)

export const setProfileEmojiPicture = createAction<FormData>(
  PROFILE_TYPES.SET_PROFILE_EMOJI_PICTURE,
)

export const setProfileWonkaPicture = createAction<FormData>(
  PROFILE_TYPES.SET_PROFILE_WONKA_PICTURE,
)

export const setProfileExternalNFTPicture = createAction<{
  nft_details: Partial<IExternalEmojiType>
  signature: string
}>(PROFILE_TYPES.SET_PROFILE_EXTERNAL_NFT__PICTURE)

export const fetchSendOTPRequest = createAction(PROFILE_TYPES.FETCH_SEND_OTP_REQUEST)

export const fetchSendOTPSuccess = createAction(PROFILE_TYPES.FETCH_SEND_OTP_SUCCESS)

export const fetchSendOTPFailure = createAction(PROFILE_TYPES.FETCH_SEND_OTP_FAILURE)

export const fetchDeleteAccOTPRequest = createAction(PROFILE_TYPES.FETCH_DELETE_ACC_OTP_REQUEST)

export const fetchDeleteAccOTPSuccess = createAction(PROFILE_TYPES.FETCH_DELETE_ACC_OTP_SUCCESS)

export const fetchDeleteAccOTPFailure = createAction(PROFILE_TYPES.FETCH_DELETE_ACC_OTP_FAILURE)

export const fetchGetNotificationsRequest = createAction(
  PROFILE_TYPES.FETCH_GET_NOTIFICATIONS_REQUEST,
)

export const fetchGetNotificationsSuccess = createAction<TEmailNotificationItem[]>(
  PROFILE_TYPES.FETCH_GET_NOTIFICATIONS_SUCCESS,
)

export const fetchGetNotificationsFailure = createAction(
  PROFILE_TYPES.FETCH_GET_NOTIFICATIONS_FAILURE,
)

export const fetchSetNotificationsRequest = createAction<{
  email_type: string
  enabled: boolean
}>(PROFILE_TYPES.FETCH_SET_NOTIFICATIONS_REQUEST)

export const fetchSetNotificationsSuccess = createAction<{
  email_type: string
  enabled: boolean
}>(PROFILE_TYPES.FETCH_SET_NOTIFICATIONS_SUCCESS)

export const fetchSetNotificationsFailure = createAction(
  PROFILE_TYPES.FETCH_SET_NOTIFICATIONS_FAILURE,
)

export const fetchDeleteAccountRequest = createAction<{ verification_code: string }>(
  PROFILE_TYPES.FETCH_DELETE_ACCOUNT_REQUEST,
)

export const fetchDeleteAccountSuccess = createAction(PROFILE_TYPES.FETCH_DELETE_ACCOUNT_SUCCESS)

export const fetchDeleteAccountFailure = createAction(PROFILE_TYPES.FETCH_DELETE_ACCOUNT_FAILURE)

export const fetchUpdateProfileEmailRequest = createAction<{ email: string }>(
  PROFILE_TYPES.FETCH_UPDATE_PROFILE_EMAIL_REQUEST,
)

export const fetchUpdateProfileEmailSuccess = createAction(
  PROFILE_TYPES.FETCH_UPDATE_PROFILE_EMAIL_SUCCESS,
)

export const fetchUpdateProfileEmailFailure = createAction(
  PROFILE_TYPES.FETCH_UPDATE_PROFILE_EMAIL_FAILURE,
)

export const fetchVerifyProfileEmailRequest = createAction<{
  verification_code: string
  email: string
}>(PROFILE_TYPES.FETCH_VERIFY_PROFILE_EMAIL_REQUEST)

export const fetchVerifyProfileEmailSuccess = createAction(
  PROFILE_TYPES.FETCH_VERIFY_PROFILE_EMAIL_SUCCESS,
)

export const fetchVerifyProfileEmailFailure = createAction(
  PROFILE_TYPES.FETCH_VERIFY_PROFILE_EMAIL_FAILURE,
)

export const fillNextMissedField = createAction(PROFILE_TYPES.FILL_NEXT_MISSED_FIELD)

export const fetchUpdateProfilePhoneNumberRequest = createAction<{ phone_number: string }>(
  PROFILE_TYPES.FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST,
)

export const fetchUpdateProfilePhoneNumberSuccess = createAction(
  PROFILE_TYPES.FETCH_UPDATE_PROFILE_PHONE_NUMBER_SUCCESS,
)

export const fetchUpdateProfilePhoneNumberFailure = createAction(
  PROFILE_TYPES.FETCH_UPDATE_PROFILE_PHONE_NUMBER_FAILURE,
)

export const fetchVerifyProfilePhoneNumberRequest = createAction<{
  verification_code: string
  phone_number: string
}>(PROFILE_TYPES.FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST)

export const fetchVerifyProfilePhoneNumberSuccess = createAction(
  PROFILE_TYPES.FETCH_VERIFY_PROFILE_PHONE_NUMBER_SUCCESS,
)

export const fetchVerifyProfilePhoneNumberFailure = createAction(
  PROFILE_TYPES.FETCH_VERIFY_PROFILE_PHONE_NUMBER_FAILURE,
)

export const changePasswordRequest = createAction<{
  old_password: string
  new_password: string
  confirm_password: string
}>(PROFILE_TYPES.CHANGE_PASSWORD_REQUEST)

export const changePasswordSuccess = createAction(PROFILE_TYPES.CHANGE_PASSWORD_SUCCESS)

// PHONE
export const saveNumber = createAction<{ phone_number: string }>(PROFILE_TYPES.SAVE_NUMBER)

export const successSaveNumber = createAction(PROFILE_TYPES.SUCCESS_SAVE_NUMBER)

export const failSaveNumber = createAction(PROFILE_TYPES.FAIL_SAVE_NUMBER)

export const verifyNumber = createAction<{
  phone_number: string
  verification_code: string
}>(PROFILE_TYPES.VERIFY_NUMBER)

export const successVerifyNumber = createAction(PROFILE_TYPES.SUCCESS_VERIFY_NUMBER)

export const failVerifyNumber = createAction(PROFILE_TYPES.FAIL_VERIFY_NUMBER)

export const fetchResendOtp = createAction<{ phone_number: string }>(PROFILE_TYPES.RESEND_OTP)

export const successResendOtp = createAction(PROFILE_TYPES.SUCCESS_RESEND_OTP)

export const failResendOtp = createAction(PROFILE_TYPES.FAIL_RESEND_OTP)

export const setUploadLoader = createAction<boolean>(PROFILE_TYPES.SET_UPLOAD_LOADER_FLAG)

export const fetchExternalNFTList = createAction<{ page: number; limit: number } | undefined>(
  PROFILE_TYPES.FETCH_EXTERNAL_NFT_LIST,
)

export const fetchExternalNFTListSuccess = createAction<IExternalEmojiType[]>(
  PROFILE_TYPES.FETCH_EXTERNAL_NFT_LIST_SUCCESS,
)

export const fetchExternalNFTListFailure = createAction(
  PROFILE_TYPES.FETCH_EXTERNAL_NFT_LIST_FAILURE,
)

export const resetExternalNFTList = createAction(PROFILE_TYPES.RESET_EXTERNAL_NFT_LIST)

export const setProfileLoading = createAction<boolean>(PROFILE_TYPES.SET_PROFILE_LOADING)

export const setWalletRequest = createAction<{
  wallet_details: { address: string }
  signature: string
}>(PROFILE_TYPES.SET_WALLET_REQUEST)

export const setWalletFailure = createAction(PROFILE_TYPES.SET_WALLET_REQUEST_FAILURE)
export const setWalletSuccess = createAction(PROFILE_TYPES.SET_WALLET_REQUEST_SUCCESS)
