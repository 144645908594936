import { createSelector } from 'reselect'
import { defaultCurrencyId } from '@core/api/config'
import {
  shouldUpdateFields,
  shouldUpdatePhone,
  shouldVerifyPhone,
  shouldVerifyEmail,
} from '@utils/helpers'
import { RootState } from './store'

const selectCurrencies = (state: RootState) => state.common.currency.data

export const defaultCurrency = createSelector([selectCurrencies], (currencies) =>
  currencies?.find((item) => item.id === defaultCurrencyId),
)

export const priceDefaultCurrency = createSelector(
  (state: RootState) => state.common,
  ({ currency, price }) =>
    Number(
      price.data?.[defaultCurrencyId] ||
        currency.data?.find((c) => c.id === defaultCurrencyId)?.price,
    ),
)

const profile = (state: RootState) => state.profile.data
const profileLoading = (state: RootState) => state.profile.loading

const isAuthenticated = createSelector([profile, profileLoading], (profileData, authLoading) => ({
  authLoading,
  isAuth: profileData?.state === 'active',
  authLoaded: profileData !== null,
  username: profileData?.username,
  isEmailVerified: profileData?.labels ? profileData?.labels[1]?.value === 'verified' : false,
  isPhoneVerified: profileData?.labels ? profileData?.labels[0]?.value === 'verified' : false,
}))

const additionalUserDataCompleted = createSelector(
  [profile],
  (data) => data !== null && !shouldUpdateFields(data),
)

const isPhoneExist = createSelector([profile], (data) => data !== null && !shouldUpdatePhone(data))

const isPhoneVerified = createSelector(
  [profile],
  (data) => data !== null && !shouldVerifyPhone(data),
)

const isEmailVerified = createSelector(
  [profile],
  (data) => data !== null && !shouldVerifyEmail(data),
)

export const configSelectors = {
  user: (state: RootState) => state.profile.data?.fee,
  username: (state: RootState) => state.profile.data?.username || '',
  profiles: (state: RootState) => state?.profile?.data?.profiles,

  profile,
  additionalUserDataCompleted,
  isPhoneVerified,
  isPhoneExist,
  isEmailVerified,
  modifyUserLoading: (state: RootState) => state.profile.modifyUserLoading,
  profileLoading,
  profileUploadPFPLoading: (state: RootState) => state.profile.uploadLoading,
  externalNFTLoader: (state: RootState) => state.profile.externalNFTLoader,

  purchasedTokens: (state: RootState) => state.purchasedTokens.purchase,
  transactions: (state: RootState) => state.purchasedTokens.transactions,

  profileNotifications: (state: RootState) => state.profile.notifications,
  profileWallet: (state: RootState) => state.profile.wallet,
  externalNFTList: (state: RootState) => state.profile.externalNFTList,

  otpPopupLoading: (state: RootState) => state.profile.otpPopup.loading,
  deleteAccountPopupLoading: (state: RootState) => state.profile.deleteAccountPopup.loading,

  emojis: (state: RootState) => state.emojis,
  emojisSearch: (state: RootState) => state.emojis.search,

  lastCountry: (state: RootState) => state.profile.data?.last_country,

  currencies: selectCurrencies,

  currenciesPrice: (state: RootState) => state.common.price.data,
  defaultCurrency,
  priceDefaultCurrency,
  showPFPModal: (state: RootState) => state.common.showPFPModal,
  preSale: (state: RootState) => state,

  isAuthenticated,

  wonkas: (state: RootState) => state.wonkas.paginated,
  wonkaFetchLoading: (state: RootState) => state.wonkas.loading.list,
  wonkaPurchaseLoading: (state: RootState) => state.wonkas.loading.purchase,
  wonkaRevealAllLoading: (state: RootState) => state.wonkas.loading.revealAll,
  wonkasTotal: (state: RootState) => state.wonkas.total,
  wonkaTokensPopup: (state: RootState) => state.wonkas.wonkaTokensPopup,

  authLoading: (state: RootState) => state.auth.loading,
  authUserFields: (state: RootState) => state.auth.userFields,
  authEmail: (state: RootState) => state.auth.userFields?.email,
  authUsername: (state: RootState) => state.auth.userName,
  authStep: (state: RootState) => state.auth.steps[state.auth.steps.length - 1],
}
