import { takeEvery, all, call, put } from 'typed-redux-saga'
import queryString from 'query-string'

import { defaultWonkaLimit } from '@constants/index'
import { API } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import { IWonkaType } from '@containers/Settings/Modals/PlayaNFTs/types'
import { ApiVersion } from '@core/api/types'
import { Action } from '@reduxjs/toolkit'
import {
  fetchWonkaTokensSuccess,
  fetchWonkaTokensFailed,
  revealWonkaSuccess,
  revealWonkaFailed,
  revealAllWonkaFailed,
  fetchWonkaTokensRequest,
  revealAllWonkaImmediatelySuccess,
  revealWonkaRequest,
  revealAllWonkaRequest,
} from './actions'
import { WONKA_TYPES } from './types'

const apiVersion = {
  apiVersion: ApiVersion.peatio,
  withHeaders: true,
}

function* fetchGetWonkas(action: Action) {
  if (fetchWonkaTokensRequest.match(action)) {
    try {
      const limit = action.payload.limit ?? defaultWonkaLimit
      const res = yield* call(
        API.get<{ data: IWonkaType[]; headers: { total: number } }>(apiVersion),
        queryString.stringifyUrl({
          url: API_URLS.wonka.listTokens,
          query: {
            limit,
            page: action.payload.page ?? 1,
          },
        }),
      )
      yield put(
        fetchWonkaTokensSuccess({
          list: res.data,
          total: Number(res.headers.total) || 0,
          limit,
          page: action.payload.page - 1 ?? 0,
        }),
      )
    } catch (err) {
      yield put(fetchWonkaTokensFailed())
    }
  }
}

function* revealWonka(action: Action) {
  if (revealWonkaRequest.match(action)) {
    try {
      const res = yield* call(
        API.post<{ data: IWonkaType }>(apiVersion),
        API_URLS.wonka.reveal,
        action.payload,
      )
      if (res.data.media?.url)
        yield* put(revealWonkaSuccess({ ...res.data, page: action.payload.page }))
    } catch (err) {
      yield* put(revealWonkaFailed())
    }
  }
}

function* revealAllWonkas(action: Action) {
  if (revealAllWonkaRequest.match(action)) {
    try {
      const limit = action.payload.limit ?? defaultWonkaLimit

      yield call(API.post(apiVersion), API_URLS.wonka.revealAll)
      // NOTE: kostyl, sometimes backend already has uploaded wonkas,
      // so it doesn't send message in wonka-media channel
      // that's why we refetch wonka list to be safe in this case
      const res = yield* call(
        API.get<{ data: IWonkaType[] }>(apiVersion),
        queryString.stringifyUrl({
          url: API_URLS.wonka.listTokens,
          query: {
            limit,
            page: action.payload.page ?? 1,
          },
        }),
      )
      if (res.data.every((wonka) => wonka.revealed === true && wonka.status === 'completed')) {
        yield put(
          revealAllWonkaImmediatelySuccess({
            list: res.data,
            limit,
            page: action.payload.page - 1 ?? 0,
          }),
        )
        return
      }

      yield* put(
        fetchWonkaTokensRequest({ page: action.payload.page, limit: action.payload.limit }),
      )
    } catch (err) {
      yield put(revealAllWonkaFailed())
    }
  }
}

export function* rootSagaWonkas() {
  yield* all([
    takeEvery(WONKA_TYPES.FETCH_WONKA_TOKENS_REQUEST, fetchGetWonkas),
    takeEvery(WONKA_TYPES.REVEAL_WONKA_REQUEST, revealWonka),
    takeEvery(WONKA_TYPES.REVEAL_ALL_WONKA_REQUEST, revealAllWonkas),
  ])
}
