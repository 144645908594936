import storage from 'redux-persist/lib/storage'

import { persistReducer } from 'redux-persist'
import { getMissedFields } from '@utils/helpers'
import { IMemberModel, IUserModel, TEmailNotificationItem } from '@core/api/types'
import { TNullable } from 'types/utilityTypes'
import { createReducer } from '@reduxjs/toolkit'
import { IExternalEmojiType } from '@containers/Settings/Modals/PlayaNFTs/types'
import encryptor from '../../encryptor'
import {
  fetchDeleteAccountFailure,
  fetchDeleteAccountRequest,
  fetchDeleteAccountSuccess,
  fetchExternalNFTList,
  fetchExternalNFTListFailure,
  fetchExternalNFTListSuccess,
  fetchGetNotificationsFailure,
  fetchGetNotificationsRequest,
  fetchGetNotificationsSuccess,
  fetchProfile,
  fetchProfileFailure,
  fetchProfileFeeSuccess,
  fetchProfileSuccess,
  fetchSendOTPFailure,
  fetchSendOTPRequest,
  fetchSendOTPSuccess,
  fetchSetNotificationsFailure,
  fetchSetNotificationsRequest,
  fetchSetNotificationsSuccess,
  fetchUpdateProfile,
  fillNextMissedField,
  resetExternalNFTList,
  setProfileEmojiPicture,
  setProfileExternalNFTPicture,
  setProfileLoading,
  setProfileWonkaPicture,
  setUploadLoader,
  successUser,
  updateProfileFailure,
  updateProfileSuccess,
  uploadProfilePicture,
} from './actions'

const initialState = {
  data: null as TNullable<IUserModel>,
  loading: false,
  modifyUserLoading: false,
  missedFields: [] as string[],
  notifications: {
    loading: false,
    data: [] as TEmailNotificationItem[],
  },
  otpPopup: {
    loading: false,
  },
  deleteAccountPopup: {
    loading: false,
  },
  uploadLoading: false,
  wallet: {
    loading: false,
  },
  externalNFTList: [] as IExternalEmojiType[],
  externalNFTLoader: false,
}

const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProfile, (state) => ({
      ...state,
      loading: true,
    }))
    .addCase(fetchProfileSuccess, (state, action) => {
      const feeData = state?.data?.fee || ({} as IMemberModel)
      return {
        ...state,
        loading: false,
        data: { ...action.payload, fee: feeData },
        missedFields: getMissedFields(action.payload),
      }
    })
    .addCase(successUser, (state, action) => ({
      ...state,
      popup: action.payload.last_country === 'United States' && !action.payload?.usa_disclaimer,
    }))
    .addCase(fetchProfileFailure, (state) => ({
      ...state,
      // non-falsy value needs to authLoaded selector work properly
      data: {} as unknown as IUserModel,
      loading: false,
    }))
    .addCase(fetchUpdateProfile, (state) => ({
      ...state,
      modifyUserLoading: true,
    }))
    .addCase(updateProfileSuccess, (state) => ({
      ...state,
      modifyUserLoading: false,
    }))
    .addCase(updateProfileFailure, (state) => ({
      ...state,
      modifyUserLoading: false,
    }))
    .addCase(fetchProfileFeeSuccess, (state, action) => {
      const feeState = state?.data?.fee || ({} as IMemberModel)

      return {
        ...state,
        data: {
          ...(state.data as IUserModel),
          fee: {
            ...feeState,
            ...action.payload,
          },
        },
      }
    })
    .addCase(fetchGetNotificationsRequest, (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        loading: true,
      },
    }))
    .addCase(fetchSetNotificationsRequest, (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        loading: true,
      },
    }))
    .addCase(fetchGetNotificationsSuccess, (state, action) => ({
      ...state,
      notifications: {
        data: [...action.payload],
        loading: false,
      },
    }))
    .addCase(fetchSetNotificationsSuccess, (state, action) => ({
      ...state,
      notifications: {
        data: [
          ...state.notifications.data.map((n) =>
            n.name === action.payload.email_type ? { ...n, enabled: action.payload.enabled } : n,
          ),
        ],
        loading: false,
      },
    }))
    .addCase(fetchSetNotificationsFailure, (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        loading: false,
      },
    }))
    .addCase(fetchGetNotificationsFailure, (state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        loading: false,
      },
    }))
    .addCase(fetchSendOTPRequest, (state) => ({
      ...state,
      otpPopup: {
        ...initialState.otpPopup,
        loading: true,
      },
    }))
    .addCase(fetchSendOTPSuccess, (state) => ({
      ...state,
      otpPopup: {
        ...initialState.otpPopup,
        loading: false,
      },
    }))
    .addCase(fetchSendOTPFailure, (state) => ({
      ...state,
      otpPopup: {
        ...initialState.otpPopup,
        loading: false,
      },
    }))
    .addCase(fetchDeleteAccountRequest, (state) => ({
      ...state,
      deleteAccountPopup: {
        ...initialState.deleteAccountPopup,
        loading: true,
      },
    }))
    .addCase(fetchDeleteAccountSuccess, (state) => ({
      ...state,
      deleteAccountPopup: {
        ...initialState.deleteAccountPopup,
        loading: false,
      },
    }))
    .addCase(fetchDeleteAccountFailure, (state) => ({
      ...state,
      deleteAccountPopup: {
        ...initialState.deleteAccountPopup,
        loading: false,
      },
    }))
    .addCase(fillNextMissedField, (state) => ({
      ...state,
      missedFields: state.missedFields.slice(1),
    }))
    .addCase(uploadProfilePicture, (state) => ({
      ...state,
      uploadLoading: true,
    }))
    .addCase(setProfileEmojiPicture, (state) => ({
      ...state,
      uploadLoading: true,
    }))
    .addCase(setProfileWonkaPicture, (state) => ({
      ...state,
      uploadLoading: true,
    }))
    .addCase(setProfileExternalNFTPicture, (state) => ({
      ...state,
      uploadLoading: true,
    }))
    .addCase(setUploadLoader, (state, action) => ({
      ...state,
      uploadLoading: action.payload,
    }))
    .addCase(fetchExternalNFTList, (state) => ({
      ...state,
      externalNFTLoader: true,
    }))
    .addCase(fetchExternalNFTListSuccess, (state, action) => ({
      ...state,
      externalNFTList: action.payload,
      externalNFTLoader: false,
    }))
    .addCase(fetchExternalNFTListFailure, (state) => ({
      ...state,
      externalNFTLoader: false,
    }))
    .addCase(resetExternalNFTList, (state) => ({
      ...state,
      externalNFTList: [],
    }))
    .addCase(setProfileLoading, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
})

const profileConfig = {
  key: 'peer_profile',
  storage,
  transforms: [encryptor],
}

export default persistReducer<typeof initialState>(profileConfig, profileReducer)
