import { combineReducers } from '@reduxjs/toolkit'
import profileReducer from './profile/reducer'
import commonReducer from './common/reducer'
import wonkaReducer from './wonkas/reducer'
import authReducer from './auth/reducer'
import purchasedTokensReducer from './purchasedTokens/reducer'
import emojisReducer from './emojis/reducer'

const rootReducer = combineReducers({
  profile: profileReducer,
  common: commonReducer,
  wonkas: wonkaReducer,
  auth: authReducer,
  purchasedTokens: purchasedTokensReducer,
  emojis: emojisReducer,
})

export default rootReducer
