import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/es/persistReducer'
import { setLoading } from '@core/helper'
import { createReducer } from '@reduxjs/toolkit'
import { IWonkaType } from '@containers/Settings/Modals/PlayaNFTs/types'
import { TNullable } from 'types/utilityTypes'
import {
  fetchWonkaTokensRequest,
  fetchWonkaTokensSuccess,
  revealAllWonkaImmediatelySuccess,
  revealAllWonkaRequest,
  revealWonkaRequest,
  revealWonkaSuccess,
  setWonkaPurchaseLoading,
  setWonkaRevealAllLoading,
  setWonkaRevealExplosion,
  setWonkaTokensPopupOpen,
} from './actions'
import encryptor from '../../encryptor'

const initialState = {
  loading: {
    list: false,
    revealAll: false,
    purchase: false,
  },
  error: '',
  total: 0,
  paginated: {} as { [key: number]: (IWonkaType & { explosionVideo: boolean })[] },
  wonkaTokensPopup: {
    isOpen: false,
    tid: null as TNullable<string>,
  },
}

const wonkaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchWonkaTokensSuccess, (state, action) => ({
      ...state,
      total: action.payload.total,
      paginated: {
        ...state.paginated,
        [action.payload.page]: action.payload.list.map((item) => ({
          ...item,
          explosionVideo: !item.revealed,
        })),
      },
      loading: setLoading(state.loading, 'list', false),
    }))
    .addCase(fetchWonkaTokensRequest, (state) => ({
      ...state,
      loading: setLoading(state.loading, 'list', true),
    }))
    .addCase(revealWonkaRequest, (state, action) => {
      const i = state.paginated[action.payload.page].findIndex(
        (wonka) => wonka.code === action.payload.code,
      )
      const revealingWonka = state.paginated[action.payload.page][i]
      revealingWonka.revealed = true
      return {
        ...state,
        paginated: {
          ...state.paginated,
          [action.payload.page]: [
            ...state.paginated[action.payload.page].slice(0, i),
            revealingWonka,
            ...state.paginated[action.payload.page].slice(i + 1),
          ],
        },
      }
    })
    .addCase(revealWonkaSuccess, (state, { payload }) => {
      let page = -1
      if (payload.page) {
        page = payload.page
      } else {
        Object.entries(state.paginated).forEach(([pageNum, wonkas]) => {
          if (wonkas.some((wonka) => wonka.code === payload.code)) {
            page = Number(pageNum)
          }
        })

        if (page === -1) {
          return state
        }
      }

      const index = state.paginated[page].findIndex((wonka) => wonka.code === payload.code)

      if (index === -1) {
        return state
      }

      const isRevealing = state.paginated[page].every(
        (wonka) =>
          wonka.code !== payload.code &&
          wonka.revealed === true &&
          (wonka.status === 'completed' || wonka.status === 'in_progress'),
      )

      return {
        ...state,
        paginated: {
          ...state.paginated,
          [page]: [
            ...state.paginated[page].slice(0, index),
            {
              ...state.paginated[page][index],
              explosionVideo: true,
              ...payload,
              revealed: true,
            },
            ...state.paginated[page].slice(index + 1),
          ],
        },
        loading: setLoading(state.loading, 'revealAll', isRevealing),
      }
    })
    .addCase(setWonkaPurchaseLoading, (state, { payload }) => ({
      ...state,
      loading: setLoading(state.loading, 'purchase', payload),
    }))
    .addCase(setWonkaRevealAllLoading, (state, { payload }) => ({
      ...state,
      loading: setLoading(state.loading, 'revealAll', payload),
    }))
    .addCase(revealAllWonkaRequest, (state) => ({
      ...state,
      loading: setLoading(state.loading, 'revealAll', true),
    }))
    .addCase(revealAllWonkaImmediatelySuccess, (state, { payload }) => ({
      ...state,
      paginated: {
        ...state.paginated,
        [payload.page]: payload.list.map((wonka, wonkaId) => ({
          ...wonka,
          explosionVideo: state.paginated[payload.page][wonkaId].revealed === false,
        })),
      },
      loading: setLoading(state.loading, 'reveal', false),
    }))
    .addCase(setWonkaRevealExplosion, (state, { payload }) => {
      const explosionIndex = state.paginated[payload.page].findIndex(
        ({ code }) => code === payload.code,
      )

      if (explosionIndex === -1) {
        return state
      }
      return {
        ...state,
        paginated: {
          ...state.paginated,
          [payload.page]: [
            ...state.paginated[payload.page].slice(0, explosionIndex),
            {
              ...state.paginated[payload.page][explosionIndex],
              explosionVideo: payload.explosionVideo,
            },
            ...state.paginated[payload.page].slice(explosionIndex + 1),
          ],
        },
        loading: setLoading(state.loading, 'reveal', false),
      }
    })
    .addCase(setWonkaTokensPopupOpen, (state, { payload }) => ({
      ...state,
      wonkaTokensPopup: {
        isOpen: payload.isOpen,
        tid: payload?.tid || null,
      },
    }))
})

const wonkaConfig = {
  key: 'peer_wonkas',
  storage,
  transforms: [encryptor],
}

export default persistReducer<typeof initialState>(wonkaConfig, wonkaReducer)
