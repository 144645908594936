import { createAction } from '@reduxjs/toolkit'
import { TNullable } from 'types/utilityTypes'
import { EMOJIS_ACTION_TYPES, TEmojiItem, TFetchEmojisPayload } from './types'

export const fetchGetEmojis = createAction<
  Partial<TFetchEmojisPayload>,
  EMOJIS_ACTION_TYPES.GET_EMOJIS_START
>(EMOJIS_ACTION_TYPES.GET_EMOJIS_START)

export const fetchGetEmojisSuccess = createAction<
  { data: TEmojiItem[]; nextPage: TNullable<number> },
  EMOJIS_ACTION_TYPES.GET_EMOJIS_SUCCESS
>(EMOJIS_ACTION_TYPES.GET_EMOJIS_SUCCESS)

export const fetchGetEmojisFailure = createAction(EMOJIS_ACTION_TYPES.GET_EMOJIS_FAILURE)

export const fetchGetEmoji = createAction<
  { code: string },
  EMOJIS_ACTION_TYPES.GET_EMOJI_BY_CODE_START
>(EMOJIS_ACTION_TYPES.GET_EMOJI_BY_CODE_START)

export const fetchGetEmojiSuccess = createAction<{ data: TEmojiItem }>(
  EMOJIS_ACTION_TYPES.GET_EMOJI_BY_CODE_SUCCESS,
)
export const fetchGetEmojiFailure = createAction<{ code: string }>(
  EMOJIS_ACTION_TYPES.GET_EMOJI_BY_CODE_FAILURE,
)

export const fetchGetSearchEmoji = createAction<
  Partial<TFetchEmojisPayload>,
  EMOJIS_ACTION_TYPES.GET_EMOJIS_BY_SEARCH_START
>(EMOJIS_ACTION_TYPES.GET_EMOJIS_BY_SEARCH_START)

export const fetchGetSearchEmojiSuccess = createAction<
  { data: TEmojiItem[]; nextPage: TNullable<number> },
  EMOJIS_ACTION_TYPES.GET_EMOJIS_BY_SEARCH_SUCCESS
>(EMOJIS_ACTION_TYPES.GET_EMOJIS_BY_SEARCH_SUCCESS)

export const fetchGetSearchEmojiFailure = createAction(
  EMOJIS_ACTION_TYPES.GET_EMOJIS_BY_SEARCH_FAILURE,
)
