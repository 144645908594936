import { all, call, put, takeLatest, select } from 'typed-redux-saga'
import queryString from 'query-string'
import { errorMessages } from '@constants/index'
import { API_URLS } from '@core/api/apiUrls'
import { configSelectors } from '@core/redux/selectors'
import { ApiVersion } from '@core/api/types'
import { Action } from '@reduxjs/toolkit'
import { API } from '../../api'
import {
  fetchGetEmoji,
  fetchGetEmojiFailure,
  fetchGetEmojiSuccess,
  fetchGetEmojis,
  fetchGetEmojisFailure,
  fetchGetEmojisSuccess,
  fetchGetSearchEmoji,
  fetchGetSearchEmojiFailure,
  fetchGetSearchEmojiSuccess,
} from './actions'
import { EMOJIS_ACTION_TYPES, TEmojiItem } from './types'

const peatioConfig = {
  apiVersion: ApiVersion.peatio,
  withHeaders: true,
}

function* getEmojisBySearch(action: Action) {
  if (fetchGetSearchEmoji.match(action)) {
    try {
      const limit = action.payload.limit || 24
      const isTag = action.payload?.searchValue?.startsWith('#')
      const searchValue = isTag ? action.payload?.searchValue?.slice(1) : action.payload.searchValue

      const basicQueryData = {
        state: 'active',
        order_by: action.payload.orderBy,
        ordering: action.payload.ordering || 'asc',
        tag: searchValue,
        limit,
      }

      const queryData = action.payload.token_group
        ? { ...basicQueryData, token_group: action.payload.token_group }
        : { ...basicQueryData }

      const url = queryString.stringifyUrl({
        url: API_URLS.token.search,
        query: { ...queryData },
      })

      const { data, status } = yield* call(
        API.get<{ data: TEmojiItem[]; status: number }>(peatioConfig),
        url,
      )

      if (status < 200 && status >= 300) throw new Error(errorMessages.other.something_went_wrong)

      const filteredData = data.filter(
        (t) =>
          t.aasm_state === 'active' &&
          (t.origin_volume === 0 || (t?.origin_volume || 0) - (t.volume || 0) > 0),
      )

      if (action.payload.page) {
        yield* put(
          fetchGetSearchEmojiSuccess({
            data: filteredData,
            // TODO get nextPage from the API
            // this is a quick fix, if the last page has data.length === limit, it will not be null
            // if there is no data or data length is less than limit, there is no next page
            nextPage: data.length === 0 || data.length < limit ? null : action.payload.page + 1,
          }),
        )
      }
    } catch (error) {
      yield* put(fetchGetSearchEmojiFailure())
    }
  }
}

function* getEmojiByCode(action: Action) {
  if (fetchGetEmoji.match(action)) {
    try {
      const url = queryString.stringifyUrl({
        url: API_URLS.token.code,
        query: { code: action.payload.code },
      })
      const { data, status } = yield* call(
        API.get<{ data: TEmojiItem; status: number }>(peatioConfig),
        url,
      )

      if (status < 200 && status >= 300) throw new Error(errorMessages.other.something_went_wrong)

      yield* put(
        fetchGetEmojiSuccess({
          data: { ...data },
        }),
      )
    } catch (error) {
      yield* put(fetchGetEmojiFailure(action.payload))
    }
  }
}

function* getEmojis(action: Action) {
  if (fetchGetEmojis.match(action)) {
    try {
      const limit = action.payload.limit || 100

      const basicQueryData = {
        state: 'active',
        kind: 'emoji',
        order_by: action.payload.orderBy,
        limit,
        page: action.payload.page || 1,
        ordering: action.payload.ordering || 'asc',
      }

      const queryData = action.payload.token_group
        ? { ...basicQueryData, token_group: action.payload.token_group }
        : { ...basicQueryData }

      const url = queryString.stringifyUrl({
        url: API_URLS.token.details,
        query: { ...queryData },
      })
      // cleanup emoji data if it is not the pagination request to prevent duplicating
      if (action.payload.page === 1) {
        yield* put(
          fetchGetEmojisSuccess({
            data: [],
            nextPage: 1,
          }),
        )
      }
      const { data, status } = yield* call(
        API.get<{ data: TEmojiItem[]; status: number }>(peatioConfig),
        url,
      )

      if (status < 200 && status >= 300) throw new Error(errorMessages.other.something_went_wrong)

      const filteredData = data.filter(
        (t) =>
          t.aasm_state === 'active' &&
          (t.origin_volume === 0 || (t?.origin_volume || 0) - (t.volume || 0) > 0),
      )

      const currentEmojisData = yield* select(configSelectors.emojis)

      if (action?.payload?.page) {
        const emojis =
          action.payload.page > 1 ? currentEmojisData?.data.concat(filteredData) : filteredData

        yield* put(
          fetchGetEmojisSuccess({
            data: emojis,
            // TODO get nextPage from the API
            // this is a quick fix, if the last page has data.length === limit, it will not be null
            // if there is no data or data length is less than limit, there is no next page
            nextPage: data.length === 0 || data.length < limit ? null : action.payload.page + 1,
          }),
        )
      }
    } catch (error) {
      yield* put(fetchGetEmojisFailure())
    }
  }
}

export function* rootSagaEmojis() {
  yield* all([
    takeLatest(EMOJIS_ACTION_TYPES.GET_EMOJIS_BY_SEARCH_START, getEmojisBySearch),
    takeLatest(EMOJIS_ACTION_TYPES.GET_EMOJIS_START, getEmojis),
    takeLatest(EMOJIS_ACTION_TYPES.GET_EMOJI_BY_CODE_START, getEmojiByCode),
  ])
}
