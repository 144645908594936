export enum PROFILE_TYPES {
  SET_WALLET_REQUEST = 'SET_WALLET_REQUEST',
  SET_WALLET_REQUEST_FAILURE = 'SET_WALLET_REQUEST_FAILURE',
  SET_WALLET_REQUEST_SUCCESS = 'SET_WALLET_REQUEST_SUCCESS',

  FETCH_PROFILE_REQUEST = 'profile/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS = 'profile/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE = 'profile/FETCH_PROFILE_FAILURE',

  FETCH_PROFILE_FEE_REQUEST = 'profile/FETCH_PROFILE_FEE_REQUEST',
  FETCH_PROFILE_FEE_SUCCESS = 'profile/FETCH_PROFILE_FEE_SUCCESS',
  FETCH_PROFILE_FEE_FAILURE = 'profile/FETCH_PROFILE_FEE_FAILURE',

  SAVE_PROFILE_REQUEST = 'profile/SAVE_PROFILE_REQUEST',
  SAVE_PROFILE_SUCCESS = 'profile/SAVE_PROFILE_SUCCESS',
  SAVE_PROFILE_FAILURE = 'profile/SAVE_PROFILE_FAILURE',

  UPDATE_PROFILE_REQUEST = 'profile/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = 'profile/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = 'profile/UPDATE_PROFILE_FAILURE',

  FETCH_UPLOAD_PROFILE_PICTURE_REQUEST = 'profile/FETCH_UPLOAD_PROFILE_PICTURE_REQUEST',
  FETCH_DELETE_PROFILE_PICTURE_REQUEST = 'profile/FETCH_DELETE_PROFILE_PICTURE_REQUEST',

  UPDATE_USER = 'profile/UPDATE_USER',
  UPDATE_USER_SUCCESS = 'profile/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE = 'profile/UPDATE_USER_FAILURE',
  UPDATE_PROFILE_EMAIL = 'profile/UPDATE_PROFILE_EMAIL',
  UPDATE_PROFILE_PHONE_NUMBER = 'profile/UPDATE_PROFILE_PHONE_NUMBER',

  LOGOUT_REQUEST = 'profile/LOGOUT_REQUEST',
  POST_COUNTRY = 'profile/POST_COUNTRY',
  GET_USA_DISCLAIMER = 'profile/GET_USA_DISCLAIMER',
  SUCCESS_USER = 'profile/SUCCESS_USER',

  SET_AUTH_POPUP = 'profile/SET_AUTH_POPUP',

  SET_PROFILE_EMOJI_PICTURE = 'profile/SET_PROFILE_EMOJI_PICTURE',
  SET_PROFILE_WONKA_PICTURE = 'profile/SET_PROFILE_WONKA_PICTURE',
  SET_PROFILE_EXTERNAL_NFT__PICTURE = 'SET_PROFILE_EXTERNAL_NFT__PICTURE',

  FETCH_SEND_OTP_REQUEST = 'profile/FETCH_SEND_OTP_REQUEST',
  FETCH_SEND_OTP_SUCCESS = 'profile/FETCH_SEND_OTP_SUCCESS',
  FETCH_SEND_OTP_FAILURE = 'profile/FETCH_SEND_OTP_FAILURE',

  FETCH_DELETE_ACC_OTP_REQUEST = 'profile/FETCH_DELETE_ACC_OTP_REQUEST',
  FETCH_DELETE_ACC_OTP_SUCCESS = 'profile/FETCH_DELETE_ACC_OTP_SUCCESS',
  FETCH_DELETE_ACC_OTP_FAILURE = 'profile/FETCH_DELETE_ACC_OTP_FAILURE',

  FETCH_GET_NOTIFICATIONS_REQUEST = 'profile/FETCH_GET_NOTIFICATIONS_REQUEST',
  FETCH_GET_NOTIFICATIONS_SUCCESS = 'profile/FETCH_GET_NOTIFICATIONS_SUCCESS',
  FETCH_GET_NOTIFICATIONS_FAILURE = 'profile/FETCH_GET_NOTIFICATIONS_FAILURE',

  FETCH_SET_NOTIFICATIONS_REQUEST = 'profile/FETCH_SET_NOTIFICATIONS_REQUEST',
  FETCH_SET_NOTIFICATIONS_SUCCESS = 'profile/FETCH_SET_NOTIFICATIONS_SUCCESS',
  FETCH_SET_NOTIFICATIONS_FAILURE = 'profile/FETCH_SET_NOTIFICATIONS_FAILURE',

  FETCH_DELETE_ACCOUNT_REQUEST = 'profile/FETCH_DELETE_ACCOUNT_REQUEST',
  FETCH_DELETE_ACCOUNT_SUCCESS = 'profile/FETCH_DELETE_ACCOUNT_SUCCESS',
  FETCH_DELETE_ACCOUNT_FAILURE = 'profile/FETCH_DELETE_ACCOUNT_FAILURE',

  FETCH_UPDATE_PROFILE_EMAIL_REQUEST = 'profile/FETCH_UPDATE_PROFILE_EMAIL_REQUEST',
  FETCH_UPDATE_PROFILE_EMAIL_SUCCESS = 'profile/FETCH_UPDATE_PROFILE_EMAIL_SUCCESS',
  FETCH_UPDATE_PROFILE_EMAIL_FAILURE = 'profile/FETCH_UPDATE_PROFILE_EMAIL_FAILURE',

  FETCH_VERIFY_PROFILE_EMAIL_REQUEST = 'profile/FETCH_VERIFY_PROFILE_EMAIL_REQUEST',
  FETCH_VERIFY_PROFILE_EMAIL_SUCCESS = 'profile/FETCH_VERIFY_PROFILE_EMAIL_SUCCESS',
  FETCH_VERIFY_PROFILE_EMAIL_FAILURE = 'profile/FETCH_VERIFY_PROFILE_EMAIL_FAILURE',

  FILL_NEXT_MISSED_FIELD = 'profile/FILL_NEXT_MISSED_FIELD',

  FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST = 'profile/FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST',
  FETCH_UPDATE_PROFILE_PHONE_NUMBER_SUCCESS = 'profile/FETCH_UPDATE_PROFILE_PHONE_NUMBER_SUCCESS',
  FETCH_UPDATE_PROFILE_PHONE_NUMBER_FAILURE = 'profile/FETCH_UPDATE_PROFILE_PHONE_NUMBER_FAILURE',

  FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST = 'profile/FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST',
  FETCH_VERIFY_PROFILE_PHONE_NUMBER_SUCCESS = 'profile/FETCH_VERIFY_PROFILE_PHONE_NUMBER_SUCCESS',
  FETCH_VERIFY_PROFILE_PHONE_NUMBER_FAILURE = 'profile/FETCH_VERIFY_PROFILE_PHONE_NUMBER_FAILURE',

  FETCH_GOOGLE_AUTH_ENABLE = 'profile/FETCH_GOOGLE_AUTH_ENABLE',
  FETCH_GOOGLE_AUTH_DISABLE = 'profile/FETCH_GOOGLE_AUTH_DISABLE',
  CHANGE_PASSWORD_REQUEST = 'profile/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',

  SAVE_NUMBER = 'profile/SAVE_NUMBER',
  SUCCESS_SAVE_NUMBER = 'profile/SUCCESS_SAVE_NUMBER',
  FAIL_SAVE_NUMBER = 'profile/FAIL_SAVE_NUMBER',
  VERIFY_NUMBER = 'profile/VERIFY_NUMBER',
  SUCCESS_VERIFY_NUMBER = 'profile/SUCCESS_VERIFY_NUMBER',
  FAIL_VERIFY_NUMBER = 'profile/FAIL_VERIFY_NUMBER',
  RESEND_OTP = 'profile/RESEND_OTP',
  SUCCESS_RESEND_OTP = 'profile/SUCCESS_RESEND_OTP',
  FAIL_RESEND_OTP = 'profile/FAIL_RESEND_OTP',

  KYC_AID = 'profile/KYC_AID',
  SET_KYC_AID = 'profile/SET_KYC_AID',
  KYC_AID_FAILURE = 'profile/KYC_AID_FAILURE',

  SET_UPLOAD_LOADER_FLAG = 'SET_UPLOAD_LOADER_FLAG',

  FETCH_EXTERNAL_NFT_LIST = 'FETCH_EXTERNAL_NFT_LIST',
  FETCH_EXTERNAL_NFT_LIST_SUCCESS = 'FETCH_EXTERNAL_NFT_LIST_SUCCESS',
  FETCH_EXTERNAL_NFT_LIST_FAILURE = 'FETCH_EXTERNAL_NFT_LIST_FAILURE',
  RESET_EXTERNAL_NFT_LIST = 'RESET_EXTERNAL_NFT_LIST',

  SET_PROFILE_LOADING = 'SET_PROFILE_LOADING',
}
