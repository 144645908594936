import { createAction } from '@reduxjs/toolkit'
import { IWonkaType } from '@containers/Settings/Modals/PlayaNFTs/types'
import { WONKA_TYPES } from './types'

export const fetchWonkaTokensRequest = createAction<
  { page: number; limit: number },
  WONKA_TYPES.FETCH_WONKA_TOKENS_REQUEST
>(WONKA_TYPES.FETCH_WONKA_TOKENS_REQUEST)

export const fetchWonkaTokensSuccess = createAction<
  { list: IWonkaType[]; total: number; limit: number; page: number },
  WONKA_TYPES.FETCH_WONKA_TOKENS_SUCCESS
>(WONKA_TYPES.FETCH_WONKA_TOKENS_SUCCESS)

export const fetchWonkaTokensFailed = createAction(WONKA_TYPES.FETCH_WONKA_TOKENS_FAILED)

export const revealWonkaRequest = createAction<
  IWonkaType & { page: number },
  WONKA_TYPES.REVEAL_WONKA_REQUEST
>(WONKA_TYPES.REVEAL_WONKA_REQUEST)

export const revealWonkaSuccess = createAction<
  IWonkaType & { page: number },
  WONKA_TYPES.REVEAL_WONKA_SUCCESS
>(WONKA_TYPES.REVEAL_WONKA_SUCCESS)

export const revealWonkaFailed = createAction(WONKA_TYPES.REVEAL_WONKA_FAILED)

export const revealAllWonkaRequest = createAction<
  { page: number; limit: number },
  WONKA_TYPES.REVEAL_ALL_WONKA_REQUEST
>(WONKA_TYPES.REVEAL_ALL_WONKA_REQUEST)

export const revealAllWonkaImmediatelySuccess = createAction<
  { list: IWonkaType[]; limit: number; page: number },
  WONKA_TYPES.REVEAL_ALL_WONKA_IMMEDIATELY_SUCCESS
>(WONKA_TYPES.REVEAL_ALL_WONKA_IMMEDIATELY_SUCCESS)

export const revealAllWonkaFailed = createAction(WONKA_TYPES.REVEAL_ALL_WONKA_FAILED)

export const setWonkaPurchaseLoading = createAction<
  boolean,
  WONKA_TYPES.SET_WONKA_PURCHASE_LOADING
>(WONKA_TYPES.SET_WONKA_PURCHASE_LOADING)

export const setWonkaRevealAllLoading = createAction<
  boolean,
  WONKA_TYPES.SET_WONKA_REVEAL_ALL_LOADING
>(WONKA_TYPES.SET_WONKA_REVEAL_ALL_LOADING)

export const setWonkaRevealExplosion = createAction<
  { page: number; code: string; explosionVideo: boolean },
  WONKA_TYPES.SET_WONKA_REVEAL_EXPLOSION
>(WONKA_TYPES.SET_WONKA_REVEAL_EXPLOSION)

export const setWonkaTokensPopupOpen = createAction<
  { isOpen: boolean; tid: string },
  WONKA_TYPES.SET_WONKA_TOKENS_POPUP
>(WONKA_TYPES.SET_WONKA_TOKENS_POPUP)
