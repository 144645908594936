import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import styles from '@components/PopUp/FullScreenPopup/full_screen_popup.module.scss'
import { configSelectors } from '@core/redux/selectors'

function UnauthorizedPopupTitle() {
  const { t } = useTranslation('nsPopupTitles')

  return (
    <>
      {t('oneSec')},
      <br />
      {t('loginFirst')}
    </>
  )
}

const expiredSessionPopupTitle = (
  <>
    your session expired,
    <br />
    pls login
  </>
)

function CongratsPopupTitle() {
  const { t } = useTranslation('nsPopupTitles')

  return (
    <>
      <span>{String.fromCodePoint(0x1f389)}</span>
      {t('congrats')}
      <br />
      {t('purchaseDone')}
      <br />
      <br />
      <span className={styles.title__sub}>{t('purchasedItems')}</span>
    </>
  )
}

function PaymentDoneTitle() {
  const { t } = useTranslation('nsPopupTitles')
  return (
    <>
      {t('yourPayment')}
      <br />
      {t('hasBeenReceived')},
      <br />
      <span className={styles.title__sub}>({t('accessAvailable')})</span>
    </>
  )
}

function WonkaTokens() {
  const tid = useSelector(configSelectors.wonkaTokensPopup)?.tid
  return (
    <>
      Transaction {tid}
      <br />
      has been processed
      <br />
      <span className={styles.title__sub}>(assets already available in profile section)</span>
    </>
  )
}

function SuccessFulPurchaseTitle() {
  const { t } = useTranslation('nsPopupTitles')
  return (
    <>
      {t('successfulPurchase')} <br /> {t('checkItems')}
    </>
  )
}

export const POPUP_TITLES = {
  auth: { unauthorizedPopupTitle: <UnauthorizedPopupTitle />, expiredSessionPopupTitle },
  payment: {
    congrats: <CongratsPopupTitle />,
    paymentDone: <PaymentDoneTitle />,
    wonkaTokens: <WonkaTokens />,
    successfulPurchase: <SuccessFulPurchaseTitle />,
  },
}
