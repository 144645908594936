import { TNullable } from 'types/utilityTypes'
import { createReducer } from '@reduxjs/toolkit'
import { TEmojiItem } from './types'
import {
  fetchGetEmoji,
  fetchGetEmojiFailure,
  fetchGetEmojiSuccess,
  fetchGetEmojis,
  fetchGetEmojisFailure,
  fetchGetEmojisSuccess,
  fetchGetSearchEmoji,
  fetchGetSearchEmojiFailure,
  fetchGetSearchEmojiSuccess,
} from './actions'

const initialState = {
  data: [] as TEmojiItem[],
  loader: false,

  current: null as TNullable<TEmojiItem>,
  currentLoader: false,
  nextPage: null as TNullable<number>,

  search: {
    data: [] as TEmojiItem[],
    loader: false,
    nextPage: null as TNullable<number>,
  },
}

const emojisReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchGetSearchEmoji, (state) => ({
      ...state,
      search: {
        ...state.search,
        data: [],
        loader: true,
      },
    }))
    .addCase(fetchGetEmoji, (state) => ({
      ...state,
      currentLoader: true,
    }))
    .addCase(fetchGetEmojis, (state) => ({
      ...state,
      loader: true,
    }))
    .addCase(fetchGetSearchEmojiFailure, (state) => ({
      ...state,
      search: {
        ...state.search,
        data: [],
        loader: false,
      },
    }))
    .addCase(fetchGetEmojiFailure, (state, action) => ({
      ...state,
      currentLoader: false,
      current: state.data.find((token) => token.code === action.payload.code) || null,
    }))
    .addCase(fetchGetEmojisFailure, (state) => ({
      ...state,
      loader: false,
    }))
    .addCase(fetchGetSearchEmojiSuccess, (state, action) => ({
      ...state,
      search: {
        loader: false,
        data: action.payload.data,
        nextPage: action.payload.nextPage,
      },
    }))
    .addCase(fetchGetEmojiSuccess, (state, action) => ({
      ...state,
      currentLoader: false,
      current: { ...action.payload.data },
    }))
    .addCase(fetchGetEmojisSuccess, (state, action) => ({
      ...state,
      loader: false,
      data: action.payload.data,
      nextPage: action.payload.nextPage,
    }))
})

export default emojisReducer
